<template>
  <b-container
    fluid
  >
    <b-row>
      <b-col
        cols="12"
        md="3"
        lg="3"
        class="p-2"
        order="3"
        order-md="4"
      >
        <PaleCard
          label="Today's Total Paid"
          variant="primary"
          :count="reports.total_today"
          :link="{ name: 'merchant.reports.qrph' }"
          :loading="fetching"
        />
      </b-col>
      <b-col
        cols="12"
        md="3"
        lg="3"
        class="p-2"
        order="3"
        order-md="4"
      >
        <PaleCard
          label="Yesterday's Total Paid"
          variant="info"
          :count="reports.total_yesteday"
          :link="{ name: 'merchant.reports.qrph' }"
          :loading="fetching"
        />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { SMerchantDashboard } from '@/services'
import { core } from '@/config/pluginInit'
import PaleCard from '@/components/custom/PaleCard'
import formatter from '@/mixins/formatter'

export default {

  name: 'MechantDashboard',

  middleware: ['auth', 'merchant'],

  components: {
    PaleCard
  },

  mixins: [formatter],

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      reports: {
        total_yesteday: 0.00,
        total_today: 0.00
      }
    }
  },

  mounted () {
    core.index()
    this.getQRPhStatistics()
  },

  methods: {
    getQRPhStatistics () {
      this.fetching = true
      return SMerchantDashboard.get().then(({ data }) => {
        this.reports.total_yesteday = this.numberFormatter(data.total_yesterday)
        this.reports.total_today = this.numberFormatter(data.total_today)
      }).finally(() => {
        this.fetching = false
      })
    }
  }
}
</script>
